import Utilities from "./Utilities";

export default class Filter  {

  createBlacklist(type) {
    var services = {};
    for (var service in window.CookieConsent.config.services) {
			var objectType = Utilities.objectType(window.CookieConsent.config.services[service].type);
			if ((objectType === 'String' && window.CookieConsent.config.services[service].type === type) ||
					(objectType === 'Array' && window.CookieConsent.config.services[service].type.indexOf(type) != -1)) {
				if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].needed === false) {
					if (window.CookieConsent.config.categories[window.CookieConsent.config.services[service].category].wanted === false) {
						services[service] = window.CookieConsent.config.services[service];
					}
				}
			}
    }

    var blacklist = [];

    for (var service in services) {
      var objectType = Utilities.objectType(services[service].search);
      if (objectType === 'String') {
        blacklist.push(services[service].search);
      } else if (objectType === 'Array') {
        for (let i = 0; i < services[service].search.length; i++) {
          blacklist.push(services[service].search[i]);
        }
      }
    }
    return blacklist;
  }

}