import { el, mount } from 'redom';
import Language from './Language';
import Utilities from "./Utilities";
import ScriptTagFilter from './ScriptTagFilter';
import BlocksFilter from './BlocksFilter';
import RemoveCookies from './RemoveCookies';

export default class Interface {

  constructor() {
    this.elements = {};
		this.scriptTagFilter = new ScriptTagFilter();
		this.blocksFilter = new BlocksFilter();
    this.removeCookies = new RemoveCookies();
    this.activeElement = '';
  }

  buildStyle() {
		return el('style',
      '#cconsent-bar, #cconsent-bar * { box-sizing:border-box }',
      '#cconsent-bar { background-color:' + window.CookieConsent.config.theme.barColor + '; color:' + window.CookieConsent.config.theme.barTextColor + '; padding:15px; text-align:right; font-family:sans-serif; font-size:14px; line-height:18px; position:fixed; bottom:0; left:0; width:100%; z-index:9999999999; transform: translateY(0); transition: transform .6s ease-in-out; transition-delay: .3s;}', 
      '#cconsent-bar.ccb--hidden {transform: translateY(100%); display:block;}', 
      '#cconsent-bar .ccb__wrapper { display:flex; flex-wrap:wrap; justify-content:flex-end; max-width:1800px; margin:0 auto}',
      '#cconsent-bar .ccb__left { align-self:center; text-align:left; margin: 15px auto;}',
      '#cconsent-bar .ccb__right { align-self:center; white-space: nowrap; margin: auto 15px;}',
      '#cconsent-bar .ccb__right > div {display:inline-block; color:#FFF;}',
      '#cconsent-bar a { text-decoration:underline; color:' + window.CookieConsent.config.theme.barTextColor + '; }',
      '#cconsent-bar button { line-height:normal; font-size:14px; border:none; padding:10px 10px; color:' + window.CookieConsent.config.theme.barMainButtonTextColor + '; background-color:' + window.CookieConsent.config.theme.barMainButtonColor + ';}',
      '#cconsent-bar a.ccb__edit { margin-right:15px }',
      '#cconsent-bar a:hover, #cconsent-bar button:hover { cursor:pointer; }',
      '#cconsent-modal { display:none; font-size:14px; line-height:18px; color:#666; width: 100vw; height: 100vh; position:fixed; left:0; top:0; right:0; bottom:0; font-family:sans-serif; font-size:14px; background-color:rgba(0,0,0,0.6); z-index:9999999999; align-items:center; justify-content:center;}',
      '@media (max-width: 600px) { #cconsent-modal { height: 100% } }',
      '#cconsent-modal h2, #cconsent-modal h3 {color:#333}',
      '#cconsent-modal.ccm--visible {display:flex}',
      '#cconsent-modal .ccm__content { max-width:600px; min-height:500px; max-height:600px; overflow-Y:auto; background-color:#EFEFEF; }',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content { max-width:100vw; height:100%; max-height:initial; }}',
      '#cconsent-modal .ccm__content > .ccm__content__heading { border-bottom:1px solid #D8D8D8; padding:35px 35px 20px; background-color:#EFEFEF; position:relative;}',
      '#cconsent-modal .ccm__content > .ccm__content__heading h2 { font-size:21px; font-weight:600; color:#333; margin: 10px 0; }',
      '#cconsent-modal .ccm__content > .ccm__content__heading a.ccm__cheading__close {text-decoration: none; font-weight:600; color:#888; cursor:pointer; padding: 7px;font-size:25px; position: absolute; right:15px; top: 10px;}',
      '#cconsent-bar a.ccm__ccb__close {text-decoration: none; cursor:pointer; padding: 5px}',
      '#cconsent-modal .ccm__content > .ccm__content__heading div.ccm_learn_more { text-align: right}',
      '#cconsent-modal h2, #cconsent-modal h3 {margin-top:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body { background-color:#FFF;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup {margin:0; border-bottom: 1px solid #D8D8D8; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head::before { position:absolute; left:35px; font-size:1em; font-weight: 600; color:#E56385; content:"✘"; display:inline-block; margin-right: 20px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.checked-5jhk .ccm__tab-head::before {content:"✔"; color:#28A834}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge { transition: transform .3s ease-out; transform-origin: 16px 6px 0; position:absolute;right:25px; top:50%; transform:rotate(0deg); transform:translateY(-50%)}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge > svg { pointer-events: none; }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head .ccm__tab-head__icon-wedge {transform:rotate(-180deg)}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {color:#333; padding:17px 35px 17px 56px; margin:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content {padding:25px 35px; margin:0}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head { transition: background-color .5s ease-out }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head:hover { background-color:#F9F9F9 }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {font-size:1.2em; font-weight:600; cursor:pointer; position:relative;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-content {display:none;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head { background-color:#F9F9F9 }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {display:flex;}',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {flex-direction:column} }',
      '@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right { margin-bottom:20px; } }',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch-component {display:flex; margin-left:55px; align-items:center;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch-component > div {font-weight:600;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch-group {width:40px; height:20px; margin:0 10px; position:relative;}',
			'#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch {position:absolute; top:0; right:0; display:flex; align-items:center;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch input {-moz-appearance: none;-o-appearance: none;-webkit-appearance: none;background-color: #fafafa;border-radius: .825em !important;border: 1px solid #565656;box-shadow: inset -1.125em 0 0 1px rgb(120 120 120) !important;height: 1.5em;margin: .25em !important;outline: none;position: relative;transition: box-shadow .2s ease-in-out;width: 2.5em;cursor: pointer !important;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .right .ccm__switch input:checked {border: 1px solid rgb(120 120 120);box-shadow: inset 1.125em 0 0 1px #28A834 !important;}',    
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content h3 {font-size:18px; margin-bottom:10px; line-height:1;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content p {margin:0; color:#444;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list:not(:empty) {margin-top:30px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list .ccm__list__title {color:#333; font-weight:600;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list ul { margin:15px 0; padding-left:15px }',
      '#cconsent-modal .ccm__footer { padding:35px; background-color:#EFEFEF; text-align:center; display: flex; align-items:center; justify-content:flex-end; }',
      '#cconsent-modal .ccm__footer button { line-height:normal; font-size:14px; transition: background-color .5s ease-out; background-color:' + window.CookieConsent.config.theme.modalMainButtonColor + '; color:' + window.CookieConsent.config.theme.modalMainButtonTextColor + '; border:none; padding:13px; min-width:110px; border-radius: 2px; cursor:pointer; }',
      '#cconsent-modal .ccm__footer button:hover { background-color:' + Utilities.lightenDarkenColor(window.CookieConsent.config.theme.modalMainButtonColor, -20) + '; }',
      '#cconsent-modal .ccm__footer button#ccm__footer__consent-modal-submit {  margin-right:10px; }',
			'.cc-hide-block {display: none !important}',
      '.sr-only { position: absolute; width:1px; height:1px; padding:0; margin:-1px; overflow: hidden; clip:rect(0,0,0,0); white-space:nowrap; border-width:0; }',
			window.CookieConsent.config.theme.customStyles
		);
  }

  buildBar() {
    return el('div#cconsent-bar.ccb--hidden', {tabindex: '0'},
        el('a.ccm__ccb__close', { href: '#' },
          el('span.sr-only', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'closeBar')),
          el('span', { 'aria-hidden': 'true' }, 'X')
        ),
        el(`div.ccb__wrapper`,
          el('div.ccb__left',
           el('div.cc-text', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barMainText'))
           ),
          el('div.ccb__right',
            el('div.ccb__button',
              el('a.ccb__edit', { href: '#'}, Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting')),
              el('button.consent-give.banner', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barBtnAcceptAll'))
            )
          )
        ),
      );
  }

  buildModal() {
    // Cookie names list middleware
    var listCookies = function(category) {
			if (! window.CookieConsent.config.categories[category].showServices) {
				return;
			}
      var list = [];
			
      for(let service in window.CookieConsent.config.services) {
        (window.CookieConsent.config.services[service].category === category) && list.push(window.CookieConsent.config.services[service]);
      }
      
      if(list.length) {
        
        var listItems = [];
        
        for(let item in list) {
          listItems.push(el('li', Language.getTranslation(list[item], window.CookieConsent.config.language.current, 'name')));
        }

        return [el('div.ccm__list', el('span.ccm__list__title', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalAffectedSolutions')), el('ul', listItems))];
      }
    }
    
    function modalTabGroups() {
      let contentItems = [];

      let i = 0;
      for (let key in window.CookieConsent.config.categories) {
        contentItems.push(el('dl.ccm__tabgroup' + '.' + key + ((window.CookieConsent.config.categories[key].checked) ? '.checked-5jhk' : ''), {'data-category':key},
                            el('dt.ccm__tab-head', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'name'),
                              el('a.ccm__tab-head__icon-wedge', { href: '#'},
                              el('span.sr-only', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'openModalTab') + ' ' + Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'name')),
                                el(document.createElementNS("http://www.w3.org/2000/svg", "svg"), { version: "1.2", preserveAspectRatio: "none", viewBox: "0 0 24 24", class: "icon-wedge-svg", "data-id": "e9b3c566e8c14cfea38af128759b91a3", style: "opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;"},
                                  el(document.createElementNS("http://www.w3.org/2000/svg", "path"), { 'xmlns:default': "http://www.w3.org/2000/svg", class: "icon-wedge-angle-down", d: "M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z", style: "fill: rgb(51, 51, 51);" })
                                )
                              ),
                            ),
                            el('dd.ccm__tab-content',
                              el('div.ccm__tab-content__left',
                                el('p', Language.getTranslation(window.CookieConsent.config.categories[key], window.CookieConsent.config.language.current, 'description')),
                                el('div.ccm__list', listCookies(key))
                              ),
                              el('div.right',
                                ( !window.CookieConsent.config.categories[key].needed) && el('div.ccm__switch-component',
                                  el('div.ccm__switch-group',
                                    el('label.ccm__switch',
                                      el('span.sr-only', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'switchText')),
                                      el('span.status-off',
                                        el('span', { 'aria-hidden': 'true' }, Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'off'))
                                      ),
                                      el('input.category-onoff', {'data-check-switch':'', role:'switch', type:'checkbox', 'data-category': key, 'aria-checked': false, checked: window.CookieConsent.config.categories[key].checked}),
                                      el('span.status-on',
                                        el('span', { 'aria-hidden': 'true' }, Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'on'))
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        );

        i++;
      }
      return contentItems;
    }

    return el('div#cconsent-modal', { tabindex: '0' },
      el('div.ccm__content', { role: 'dialog', 'aria-labelledby': 'dialog-title'},
        el('div.ccm__content__heading',
          el('a.ccm__cheading__close', { href: '#' },
            el('span.sr-only', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'closeModal')),
            el('span', { 'aria-hidden': 'true' }, 'X')
          ),
          el('h2#dialog-title', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainTitle')),
          el('p',
            Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalMainText'),
          ),
          window.CookieConsent.config.modalMainTextMoreLink && el('div.ccm_learn_more', '[',
            el('a', { href: window.CookieConsent.config.modalMainTextMoreLink, rel: 'noopener noreferrer' }, Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'learnMore')),
            ']'
          ),
        ),
        el('div.ccm__content__body',
          el('div.ccm__tabs',
            modalTabGroups()
          )
        ),
        el('div.ccm__footer',
          el('button#ccm__footer__consent-modal-submit', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalBtnSave')),
          el('button.consent-give', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'modalBtnAcceptAll'))
        )
      )
    );
  }

  modalRedrawIcons() {
    var tabGroups = this.elements['modal'].querySelectorAll('.ccm__tabgroup');
    for(let tabGroup of tabGroups) {
      if(window.CookieConsent.config.categories[tabGroup.dataset.category].checked) {
        if(!tabGroup.classList.contains('checked-5jhk')) {
          tabGroup.classList.add('checked-5jhk');
        }
      }
      else {
        if(tabGroup.classList.contains('checked-5jhk')) {
          tabGroup.classList.remove('checked-5jhk');
        }
      }
      if (tabGroup.querySelector("input[class='category-onoff']")) {
        tabGroup.querySelector("input[class='category-onoff']").checked = window.CookieConsent.config.categories[tabGroup.dataset.category].checked;
        tabGroup.querySelector("input[class='category-onoff']").setAttribute('aria-checked', window.CookieConsent.config.categories[tabGroup.dataset.category].checked ? true : false);
      }
    }
  }

  render(name, elem, callback) {
    var area = document.createElement('textarea');
    area.innerHTML = elem.innerHTML;
    elem.innerHTML = area.value;

    if (typeof callback === 'undefined') callback = function(){};
    if (typeof this.elements[name] !== 'undefined') {
      this.elements[name].parentNode.replaceChild(elem, this.elements[name]);
      this.elements[name] = elem;
      callback(elem);
      return elem;
    } else {
      var insertedElem = mount(document.body, elem);
      if (insertedElem) {
        this.elements[name] = insertedElem;
      }
      callback(insertedElem);
      return insertedElem;
    }
  }

  buildInterface(callback) {
    if (typeof callback === 'undefined') callback = function(){};
    var that = this;

    Utilities.ready(function() {
      that.render('style', that.buildStyle());
      that.render('bar', that.buildBar(), (bar) => {
        // Show the bar after a while
        if ( ! window.CookieConsent.config.cookieExists) {
          setTimeout(() => {
            bar.classList.remove('ccb--hidden');
            bar.querySelector('.ccm__ccb__close').focus();
          }, window.CookieConsent.config.barTimeout);
        }
      });

      that.render('modal', that.buildModal(), () => {
        that.modalRedrawIcons();
      });

      callback();
    });
  }

  addEventListeners(elements) {
    // If you click Accept all cookies
    var buttonConsentGive = document.querySelectorAll('.consent-give');
    for(let button of buttonConsentGive) {
      button.addEventListener('click', () => {
        // We set config to full consent
        for(let key in window.CookieConsent.config.categories) {
          window.CookieConsent.config.categories[key].wanted = true;
          window.CookieConsent.config.categories[key].checked = true;
        }
        this.buildCookie((cookie) => {
          this.setCookie(cookie, () => {
            this.elements['bar'].classList.add('ccb--hidden');
            this.elements['modal'].classList.remove('ccm--visible');
            this.writeBufferToDOM();
            setTimeout(() => {
              location.reload();
            }, 100);
          });
        });
      });
    }

    // If you click Cookie settings and open modal
    Array.prototype.forEach.call(document.getElementsByClassName('ccb__edit'), (edit) => {
      edit.addEventListener('click', (event) => {
        event.preventDefault();
        window.CookieConsent.lastActiveElement = document.activeElement;
        this.elements['modal'].classList.add('ccm--visible');
        this.elements['modal'].querySelector('.ccm__cheading__close').focus();
      });
    });

    // If you click trough the tabs on Cookie settings
    // If you click on/off switch
    this.elements['modal'].querySelector('.ccm__tabs').addEventListener('click', (event) => {
      // If you click trough the tabs on Cookie settings
      if (event.target.classList.contains('ccm__tab-head') || event.target.classList.contains('ccm__tab-head__icon-wedge')) {
        if (event.target.classList.contains('ccm__tab-head__icon-wedge')) {
          event.preventDefault();
        }
        function getDlParent(eventTarget) {
          var parent = eventTarget.parentNode;
          if(parent.nodeName !== 'DL') {
            return getDlParent(parent);
          } else {
            return parent;
          }
        }
        
        var parentDl = getDlParent(event.target);

        let span = event.target.querySelector("span").textContent;
        if(parentDl.classList.contains('ccm__tabgroup--open')) {
          parentDl.classList.remove('ccm__tabgroup--open');
          let newString = span.replace(Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'closeModalTab'), Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'openModalTab'));
          event.target.querySelector("span").textContent = newString;
        } else {
          parentDl.classList.add('ccm__tabgroup--open');
          let newString = span.replace(Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'openModalTab'), Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'closeModalTab'));
          event.target.querySelector("span").textContent = newString;
        }
      }

      // If you click on/off switch
      if (event.target.classList.contains('category-onoff')) {
        window.CookieConsent.config.categories[event.target.dataset.category].wanted =
        window.CookieConsent.config.categories[event.target.dataset.category].checked = (event.target.checked === true) ? true : false;
        var dt = document.querySelector('.ccm__tabgroup.' + event.target.dataset.category);
        if(event.target.checked === false && dt.classList.contains('checked-5jhk')) {
          dt.classList.remove('checked-5jhk');
          event.target.setAttribute('aria-checked', false);
        } else {
          dt.classList.add('checked-5jhk');
          event.target.setAttribute('aria-checked', true);
        }
      }
    });

    // If you click close on bar
    this.elements['bar'].querySelector('.ccm__ccb__close').addEventListener('click', (event) => {
      let switchElements = this.elements['modal'].querySelectorAll('.ccm__switch input');

      Array.prototype.forEach.call(switchElements, (switchElement) => {
        window.CookieConsent.config.categories[switchElement.dataset.category].wanted = switchElement.checked;
      });

      this.buildCookie((cookie) => {
        this.setCookie(cookie, () => {
          this.elements['modal'].classList.remove('ccm--visible');
          this.elements['bar'].classList.add('ccb--hidden');
          this.writeBufferToDOM();
          setTimeout(() => {
            location.reload();
          }, 100);
        });
      });
    });

    // If you click close on open modal
    this.elements['modal'].querySelector('.ccm__cheading__close').addEventListener('click', (event) => {
      event.preventDefault();
      this.elements['modal'].classList.remove('ccm--visible');
      window.CookieConsent.lastActiveElement.focus();
    });

    // If you click submit on cookie settings
    document.getElementById('ccm__footer__consent-modal-submit').addEventListener('click', () => {
      let switchElements = this.elements['modal'].querySelectorAll('.ccm__switch input');

      Array.prototype.forEach.call(switchElements, (switchElement) => {
        window.CookieConsent.config.categories[switchElement.dataset.category].wanted = switchElement.checked;
      });

      this.buildCookie((cookie) => {
        this.setCookie(cookie, () => {
          this.elements['modal'].classList.remove('ccm--visible');
          this.elements['bar'].classList.add('ccb--hidden');
					this.writeBufferToDOM();
          setTimeout(() => {
            location.reload();
          }, 100);
        });
      });
    });
  
    var focusableBarEls = this.elements['bar'].querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
    this.focusableBarEls = Array.prototype.slice.call(focusableBarEls);
    this.elements['bar'].addEventListener('keydown', (event) => {
      switch(event.key) {
        case 'Escape':
          if (!this.elements['bar'].classList.contains('ccb--hidden')) {
            this.elements['bar'].classList.add('ccb--hidden');
            let switchElements = this.elements['modal'].querySelectorAll('.ccm__switch input');

            Array.prototype.forEach.call(switchElements, (switchElement) => {
              window.CookieConsent.config.categories[switchElement.dataset.category].wanted = switchElement.checked;
            });
      
            this.buildCookie((cookie) => {
              this.setCookie(cookie, () => {
                this.elements['modal'].classList.remove('ccm--visible');
                this.elements['bar'].classList.add('ccb--hidden');
                this.writeBufferToDOM();
                setTimeout(() => {
                  location.reload();
                }, 100);
              });
            });
          }
        case 'Tab':
          if (this.focusableBarEls.length === 1) {
            event.preventDefault();
            break;
          }
          else if (event.shiftKey) {
            if (document.activeElement === this.focusableBarEls[0] ) {
              event.preventDefault();
              this.focusableBarEls[this.focusableBarEls.length-1].focus();
            }
          }
          else {
            if (document.activeElement === this.focusableBarEls[this.focusableBarEls.length-1] ) {
              event.preventDefault();
              this.focusableBarEls[0].focus();
            }
          }
          break;
        default:
          break;
      }
    });

    var focusableModaleEls = this.elements['modal'].querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
    this.focusableModaleEls = Array.prototype.slice.call(focusableModaleEls);
    this.elements['modal'].addEventListener('keydown', (event) => {
      switch(event.key) {
        case 'Escape':
          if (this.elements['modal'].classList.contains('ccm--visible')) {
            this.elements['modal'].classList.remove('ccm--visible');
            window.CookieConsent.lastActiveElement.focus();
          }
        case 'Tab':
          if (this.focusableModaleEls.length === 1) {
            event.preventDefault();
            break;
          }
          else if (event.shiftKey) {
            if (document.activeElement === this.focusableModaleEls[0] ) {
              event.preventDefault();
              this.focusableModaleEls[this.focusableModaleEls.length-1].focus();
            }
          }
          else {
            if (document.activeElement === this.focusableModaleEls[this.focusableModaleEls.length-1] ) {
              event.preventDefault();
              this.focusableModaleEls[0].focus();
            }
          }
          break;
        default:
          break;
      }
    });
  }

  writeBufferToDOM() {
    for(let action of window.CookieConsent.buffer.appendChild) {
      if (window.CookieConsent.config.categories[action.category].wanted === true) {
        Node.prototype.appendChild.apply(action.this, action.arguments);
      }
    }

    for(let action of window.CookieConsent.buffer.insertBefore) {
      if (window.CookieConsent.config.categories[action.category].wanted === true) {
        action.arguments[1] = (action.arguments[0].parentNode === null) ? action.this.lastChild : action.arguments[1];
        Node.prototype.insertBefore.apply(action.this, action.arguments);
      }
    }
  }

  buildCookie(callback) {
    let cookie = {
      version: window.CookieConsent.config.cookieVersion,
      categories: {},
      services: []
    };
    
    for(let key in window.CookieConsent.config.categories) {
      cookie.categories[key] = {
        wanted: window.CookieConsent.config.categories[key].wanted,
      };
    }

    cookie.services = Utilities.listGlobalServices();
  
    if (callback) callback(cookie);
    return cookie;
  }
  
  setCookie(cookie, callback) {
    const expires_in = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `cconsent=${JSON.stringify(cookie)}; expires=${expires_in}; path=/;`;
    if (callback) callback();
  }
}
